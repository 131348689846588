import { defineStore } from "pinia";
import { httpsCallable } from "firebase/functions";
import {useFirebaseFunctions} from "@/helpers/useFirebaseFunctions";
export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    drawer: window.innerWidth > 768,
    showUpgrade: false,
    prices: [],
    products: [],
    pricesLoaded: false,
    productsLoaded: false
  }),
  actions: {
    async loadPrices() {
        const functions = useFirebaseFunctions();
        const loadPrices = httpsCallable(functions, 'getPrices');
        const response = await loadPrices();
        this.prices = response.data;
        this.pricesLoaded = true;
    },
    async loadProducts() {
      const functions = useFirebaseFunctions();
      const loadProducts = httpsCallable(functions, 'getProducts');
      const response = await loadProducts();
      this.products = response.data;
      this.productsLoaded = true;
    },
  }
});
