export default {
  email:  [
    v => !!v || 'E-mail is required',
    v => /.+@.+\..+/.test(v) || 'E-mail must be a valid email address',
  ],
  required: [
    v => !!v || 'This field is required',
  ],
  web: [
    v => !!v || 'This field is required',
    v => {
      const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})([\/\w .-]*)*\/?$/;
      return pattern.test(v) || 'Invalid URL';
    }
  ],
  password: [
    v => !!v || 'Password is required',
    v => v.length >= 6 || 'Password must be at least 6 characters long',
    v => v.length <= 64 || 'Password must not exceed 64 characters',
    v => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
    v => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
    v => /[0-9]/.test(v) || 'Password must contain at least one number',
  ],
}
