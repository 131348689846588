<template>
  <admin-layout v-if="$route.meta.isAdmin">
    <RouterView />
  </admin-layout>
  <RouterView v-else />

  <v-snackbar v-model="notification.error" color="error" top>
    {{ notification.error }}
  </v-snackbar>
  <v-snackbar v-model="notification.success" color="green" top>
    {{ notification.success }}
  </v-snackbar>

  <upgrade v-model="appStore.showUpgrade" v-if="userStore.canUpgrade"></upgrade>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
import {onBeforeUnmount, watch} from 'vue'
import {useFirebaseAuth} from "vuefire";
import {useNotificationStore, useAppStore, useUserStore} from "@/stores";
const router = useRouter()
const route = useRoute();
const auth = useFirebaseAuth()
const notification = useNotificationStore();
const appStore = useAppStore();
const userStore = useUserStore();


import AdminLayout from "@/layouts/AdminLayout.vue";
import Upgrade from "@/components/Public/Upgrade.vue";

const authListener = auth.onAuthStateChanged(function(user) {
  if (!user && route.meta.requiresAuth) { // not logged in
    router.push({name: 'login'})
  }
})
onBeforeUnmount(() => {
  // clear up listener
  authListener()
})

watch(() => appStore.showUpgrade, (newValue) => {
  if (newValue) {
    if(!appStore.pricesLoaded) {
      appStore.loadPrices();
    }

    if(!appStore.productsLoaded) {
      appStore.loadProducts();
    }
  }
});


auth.onAuthStateChanged(function(user) {
  if (!user) {
    appStore.$reset();
    notification.$reset();
    userStore.$reset();
  }
});

</script>
<style>
body {
  font-family: "Poppins", sans-serif;
}

:root {
  --default-background: #27262B;
  --v-theme-surface: green;
  --default-text: #fff;
  --default-icon: #000;
  --default-icon-background:#fff;
}

.grecaptcha-badge {
  display:none !important;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
}

</style>
