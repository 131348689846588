/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
// Plugins
import './main.css'
import { registerPlugins } from '@/plugins'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

const app = createApp(App)
// import VCalendar from 'v-calendar';
// import 'v-calendar/style.css';

// Use plugin with optional defaults
// app.use(VCalendar, {})
registerPlugins(app)


app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHA_TOKEN })

app.mount('#app')
