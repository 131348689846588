
import { defineStore } from "pinia";
import {getCurrentUser} from "vuefire";
import { getIdTokenResult } from "firebase/auth";

import {getDocs, query, where, limit} from "firebase/firestore";
import {cards, subscriptions} from "@/plugins/firebase";
import {httpsCallable} from "firebase/functions";
import {useFirebaseFunctions} from "@/helpers/useFirebaseFunctions";
export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    cards: null,
    card: null,
    user: null,
    subscription: null,
    premium: false,
    has2FA: false,
    admin: false,
    premiumParent: null
  }),
  getters: {
    cardLimit() {
      if(!this.subscription) return 1;

      return parseInt(this.subscription?.plan?.metadata?.limit||1);
    },
    canUpgrade() {
      if(this.card?.locked) {
        return false;
      }

      if(this.premium) {
        return false;
      }

      return true;
    }
  },
  actions: {
    async loadUser() {
        this.user = await getCurrentUser();

        if(this.user) {
          const vm = this;
          getIdTokenResult(this.user).then((idTokenResult) => {
            vm.admin = !!idTokenResult.claims.admin;
            vm.has2FA = !!idTokenResult.claims.firebase.sign_in_second_factor;
          }).catch((error) => {
            console.error("Error fetching ID token result:", error);
          });
        }

    },
    async loadInheritedRights(force = false) {
      if(this.premiumParent !== null && !force) return;
      const functions = useFirebaseFunctions();
      const removeAccess = httpsCallable(functions, 'isPremiumCard');

      if(!this.card.authorized_uids || !this.card.authorized_uids.length) {
        this.premiumParent = false;
        return;
      }

      const response = await removeAccess({card_ids: this.card.authorized_uids})
      this.premiumParent = response.data;

    },
    async loadSubscription(force = false) {
      if(!this.user) return;
      if (this.subscription !== null && !force) return;

      try {
        const functions = useFirebaseFunctions();

        const getSubscription = httpsCallable(functions, 'getSubscription')
        const result = await getSubscription();

        if (result.data) {
          this.subscription = result.data;
          this.premium = true;
        } else {
          this.subscription = false;
          this.premium = false;
        }
      } catch (e) {
        console.error(e);
      }



    },
    async loadCards(force = false) {
      if (!this.user) return;

      if (this.cards && !force) return;

      // Create the two queries
      const userOwnedCardsQuery = query(
        cards,
        where("owner_uid", "==", this.user.uid)
      );
      const authorizedCardsQuery = query(
        cards,
        where("authorized_uids", "array-contains", this.user.uid)
      );


      const [ownedCardsSnapshot, authorizedCardsSnapshot] = await Promise.all([
        getDocs(userOwnedCardsQuery),
        getDocs(authorizedCardsQuery),
      ]);

      // Merge the results
      const ownedCards = ownedCardsSnapshot.docs;
      const authorizedCards = authorizedCardsSnapshot.docs;

      // Combine the two sets of cards, avoiding duplicates
      const mergedCards = new Map();
      ownedCards.forEach((doc) => mergedCards.set(doc.id, {id: doc.id, ...doc.data()}));
      authorizedCards.forEach((doc) => mergedCards.set(doc.id, {id: doc.id, ...doc.data()}));

      this.card = ownedCards[0].data();
      this.card.id = ownedCards[0].id;
      // Convert the Map back to an array
      this.cards = Array.from(mergedCards.values());

      // Loads the rights a user might get from a authorized cardmanager.
      this.loadInheritedRights();
    },
  },
});
