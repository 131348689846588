import { defineStore } from "pinia";
import MapFirebaseErrors from "@/helpers/MapFirebaseErrors";
export const useNotificationStore = defineStore({
  id: "notification",
  state: () => ({
    error: null,
    success: null,
  }),
  actions: {
    setError(error) {
      const asString = error instanceof Error ? error.message : error;
      this.error = MapFirebaseErrors(asString);
    },
    setSuccess(success) {
      this.success = success;
    },
  },
});
