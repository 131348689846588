import { createRouter, createWebHistory } from 'vue-router';
import { getCurrentUser } from 'vuefire';
import {useUserStore} from "@/stores";
const routes = [
  {
    path: '/',
    redirect: '/cards',
  },
  {
    path: '/setup/2fa',
    name: 'setup-2fa',
    component: () => import('../pages/Admin/2fa.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../pages/Admin/Account.vue'),
    meta: {
      title: 'Account',
      requiresAuth: true,
      isAdmin: true
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../pages/Admin/Admin.vue'),
    meta: {
      title: 'Admin',
      requiresAuth: true,
      requiresAdmin: true,
      isAdmin: true
    },
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('../pages/Admin/Cards.vue'),
    meta: {
      title: 'Digital cards',
      requiresAuth: true,
      requiresSubscription: true,
      isAdmin: true
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('../pages/Admin/Analytics.vue'),
    meta: {
      title: 'Analytics',
      requiresAuth: true,
      requiresSubscription: true,
      isAdmin: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Admin/Login.vue'),
    meta: {
      requireGuest: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/Admin/Register.vue'),
    meta: {
      requireGuest: true
    }
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: () => import('../pages/Admin/PasswordReset.vue'),
  },
  {
    path: '/cards/:id',
    name: 'cards_edit',
    component: () => import('../pages/Admin/UpdateCard.vue'),
    meta: {
      title: 'Edit card',
      requiresAuth: true,
      isAdmin: true
    },
  },
  {
    path: '/process-payment',
    name: 'process-payment',
    component: () => import('../pages/Admin/ProcessPayments.vue'),
    meta: {
      title: 'Edit card',
      requiresAuth: true,
    },
  },
  {
    path: '/:id',
    name: 'public_card',
    component: () => import('../pages/Public/Card.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../pages/Shared/404.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(async (to) => {
  const userStore = useUserStore();
  await userStore.loadUser();
  if(userStore.user) {
    await userStore.loadSubscription();
    await userStore.loadCards();

  }

  if (to.meta.requiresAuth) {
    if (!userStore.user) {
      return {
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      };
    }
  }

  if (to.meta.requiresAdmin) {
    if (!userStore.admin) {
      return {
        path: '/cards',
      };
    }
  }

  if(to.meta.requireGuest && userStore.user) {
    const redirect = to.query.redirect || 'cards';
    return redirect;
  }
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath
  }
})

export default router;
