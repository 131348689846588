import { useFirebaseApp } from 'vuefire'
import {getFunctions, connectFunctionsEmulator } from 'firebase/functions'

export function useFirebaseFunctions() {
  const functions =  getFunctions(useFirebaseApp(), 'europe-west1')

  if (import.meta.env.VITE_USE_EMULATOR == 1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  return functions
}
