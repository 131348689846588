import extractCodeFromFirebaseError from "@/helpers/extractCodeFromFirebaseError";

export default error => {

  const code = extractCodeFromFirebaseError(error)

  if (code) {
    switch (code) {
      case "auth/email-already-in-use":
        return "Email is in use";
      case "auth/invalid-email":
        return "Invalid email";
      case "auth/weak-password":
        return "Password too weak";
      case "auth/user-not-found":
        return "User not found";
      case "auth/wrong-password":
        return "Wrong password";
      default:
        return error;
      }
  } else {
    return error
  }
}
