/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles'
// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import {
  mdiAccount, mdiAccountPlus,
  mdiArrowRight,
  mdiBookOpenVariantOutline, mdiBriefcase,
  mdiCamera,
  mdiCardAccountDetails,
  mdiCards,
  mdiCheckCircle,
  mdiClose,
  mdiContentCopy,
  mdiCrown,
  mdiDelete,
  mdiDotsVertical,
  mdiDragHorizontalVariant, mdiDropbox,
  mdiEmail,
  mdiEye,
  mdiFacebook, mdiFax, mdiGithub, mdiGitlab, mdiGoogleDrive,
  mdiImage,
  mdiInstagram, mdiJira, mdiLink,
  mdiLinkedin,
  mdiLinkVariant,
  mdiLock, mdiLockOpen,
  mdiLogout,
  mdiMagnify,
  mdiMapMarker, mdiMicrosoftTeams,
  mdiPencil,
  mdiPhone,
  mdiPinterest,
  mdiPlusBoxOutline,
  mdiPoll,
  mdiQrcode,
  mdiReddit, mdiSalesforce,
  mdiSkype, mdiSlack,
  mdiSnapchat,
  mdiSoundcloud, mdiTrello, mdiTwitch,
  mdiTwitter,
  mdiViewDashboardOutline,
  mdiWalletBifold,
  mdiWeb, mdiWechat, mdiWhatsapp,
  mdiYoutube
} from "@mdi/js";
import { h } from 'vue'
// Composables
import { createVuetify } from 'vuetify'
import {siDiscord, siThreads, siTiktok, siSoundcloud} from "simple-icons";
import workPhone from '../assets/workphone.svg?raw';
const siIcons = {
  'tiktok': siTiktok.svg,
  'discord': siDiscord.svg,
  'threads': siThreads.svg,
  'workphone': workPhone
}

const addClassToSvg = (svgContent, className) => {
  return svgContent.replace(/<svg /, `<svg class="${className}" `);
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'dark',
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      'mdi-account': mdiAccount,
      'mdi-eye': mdiEye,
      'mdi-close': mdiClose,
      'mdi-magnify': mdiMagnify,
      'mdi-plus-box-outline': mdiPlusBoxOutline,
      'mdi-crown': mdiCrown,
      'mdi-dots-vertical': mdiDotsVertical,
      'mdi-account-plus': mdiAccountPlus,
      'mdi-lock': mdiLock,
      'mdi-lock-open': mdiLockOpen,
      'mdi-qrcode': mdiQrcode,
      'mdi-delete': mdiDelete,
      'mdi-card-account-details': mdiCardAccountDetails,
      'mdi-content-copy': mdiContentCopy,
      'mdi-image': mdiImage,
      'mdi-drag-horizontal-variant': mdiDragHorizontalVariant,
      'mdi-view-dashboard-outline': mdiViewDashboardOutline,
      'mdi-pencil': mdiPencil,
      'arrow-right': mdiArrowRight,
      'mdi-poll': mdiPoll,
      'mdi-email': mdiEmail,
      'mdi-phone': mdiPhone,
      'mdi-check-circle': mdiCheckCircle,
      'mdi-cards': mdiCards,
      'mdi-wallet-bifold': mdiWalletBifold,
      'mdi-logout': mdiLogout,
      'mdi-camera': mdiCamera,
      'mdi-facebook': mdiFacebook,
      'mdi-book-open-variant-outline': mdiBookOpenVariantOutline,
      'mdi-linkedin': mdiLinkedin,
      'mdi-link-variant':mdiLinkVariant,
      'mdi-link': mdiLink,
      'mdi-web': mdiWeb,
      'mdi-twitch': mdiTwitch,
      'mdi-fax': mdiFax,
      'mdi-map-marker': mdiMapMarker,
      'mdi-instagram': mdiInstagram,
      'mdi-twitter': mdiTwitter,
      'mdi-snapchat': mdiSnapchat,
      'mdi-youtube': mdiYoutube,
      'mdi-pinterest': mdiPinterest,
      'mdi-reddit': mdiReddit,
      'mdi-skype': mdiSkype,
      'mdi-soundcloud': mdiSoundcloud,
      'mdi-whatsapp': mdiWhatsapp,
      'mdi-wechat': mdiWechat,
      'mdi-briefcase': mdiBriefcase,
      'mdi-slack': mdiSlack,
      'mdi-microsoft-teams': mdiMicrosoftTeams,
      'mdi-google-drive': mdiGoogleDrive,
      'mdi-dropbox': mdiDropbox,
      'mdi-trello':mdiTrello,
      'mdi-jira': mdiJira,
      'mdi-github':mdiGithub,
      'mdi-gitlab': mdiGitlab,
      'mdi-salesforce': mdiSalesforce,
      'mdi-arrow-right': mdiArrowRight
    },
    sets: {
      mdi,
      si: {
        component: (props) => {
          const svgContent = siIcons[props.icon] || '';
          const svgWithClass = addClassToSvg(svgContent, 'v-icon__svg');
          return h(props.tag, {
            class: ['si', props.icon],
            innerHTML: svgWithClass
          });
        }
      }
    },
  },
})


export default vuetify;
