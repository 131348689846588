/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import { firebaseApp } from '@/plugins/firebase'
import {useFirebaseAuth, VueFire, VueFireAuth} from 'vuefire'
import router from "@/plugins/router";
import {pinia} from "@/plugins/pinia";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'


Bugsnag.start({
  apiKey: 'c2cd7accc7b972dd7e8ce67e77fea408',
  plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: 'c2cd7accc7b972dd7e8ce67e77fea408' })

export function registerPlugins (app) {
  const bugsnagVue = Bugsnag.getPlugin('vue')

  app.use(bugsnagVue)
  app.use(vuetify)
  app.use(router)
  app.use(pinia)
  app.use(VueFire, {
      firebaseApp,
      // add modules like VueFireAuth, ...
      modules: [
        VueFireAuth()
      ],
    })
}

