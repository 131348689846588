<template>
  <v-layout>
    <navigation-drawer ></navigation-drawer>
    <v-main>
        <slot>
        </slot>
    </v-main>
  </v-layout>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter();
import NavigationDrawer from "@/components/Admin/NavigationDrawer.vue";
</script>

<style lang="scss" scoped>

:deep(.v-toolbar) {
  border:transparent !important;
}


</style>
