<template>
  <v-navigation-drawer v-model="appStore.drawer" width="140" class="bg-black border-0 admin-drawer">
    <div class="flex justify-center py-4 px-4">
      <v-img :src="logo"></v-img>
    </div>

    <div class="flex flex-col items-center mt-8 gap-8 ">
      <v-avatar color="blue" class="pa-0" size="70">
        <v-img :src="userStore.card.photo" v-if="userStore.card.photo"></v-img>
        <span v-else>{{userStore.user?.displayName?.charAt(0) || 'S'}}</span>
      </v-avatar>

      <v-list density="compact" item-props nav class="flex flex-col gap-2">
        <v-list-item
          v-for="item in items.filter(i => i.hideLocked ? !userStore.card.locked : true)"
          :key="item.title"
          :to="!item.external ? item.to : undefined"
          link
          @click="item.external ? openExternalLink(item.to) : null"
        >
          <div class="flex items-center flex-col justify-center gap-4 py-2">
            <v-icon :icon="item.prependIcon" size="40" color="grey"></v-icon>
            <span>{{ item.title }}</span>
            <v-chip color="yellow" size="small" v-if="!userStore.premium && item.premium" class="ml-2">Premium</v-chip>
          </div>
        </v-list-item>
      </v-list>

      <template v-if="userStore.canUpgrade">
        <v-divider class="w-full opacity-50 max-w-[70px]" color="grey"></v-divider>

        <div class="px-4">
          <span class="mb-4 block text-center">Looking for more features?</span>
          <v-btn color="white" class="w-full" @click="appStore.showUpgrade = true" size="small" rounded>
            Upgrade
          </v-btn>
        </div>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useAppStore, useUserStore } from "@/stores";
import { useRouter } from "vue-router";
import logo from "@/assets/logo.svg?url";

const appStore = useAppStore();
const router = useRouter();
const items = ref([
  {
    title: "Cards",
    prependIcon: "$mdi-view-dashboard-outline",
    link: true,
    to: "/cards",
    premium: false,
    hideLocked: false
  },
  {
    title: "Analytics",
    prependIcon: "$mdi-poll",
    link: true,
    to: "/analytics",
    premium: true,
    hideLocked: true
  },
  {
    title: "Account",
    prependIcon: "$mdi-account",
    link: true,
    to: "/account",
    hideLocked: false
  },
  {
    title: "Manual",
    prependIcon: "$mdi-book-open-variant-outline",
    link: true,
    external: true,
    to: "https://www.sammy.nl/instructies-platform/",
    hideLocked: false
  },
]);

const userStore = useUserStore();

onMounted(() => {
  if (userStore.admin) {
    items.value.push({
      title: "Admin",
      prependIcon: "$mdi-lock",
      link: true,
      to: "/admin",
    });
  }
});

const openExternalLink = (url) => {
  window.open(url, "_blank");
};
</script>
