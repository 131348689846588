<template>
  <v-dialog max-width="1100" v-if="userStore.user">
    <template v-slot:default="{ isActive }" v-if="!appStore.pricesLoaded && !appStore.productsLoaded">
      <div class="flex items-center justify-center">
        <v-progress-circular indeterminate  color="primary"></v-progress-circular>
      </div>
    </template>
    <template v-slot:default="{ isActive }" v-else>
      <v-card title="Upgrade to Sammy Premium"  >

        <template v-slot:append>
          <v-btn icon="$mdi-close" @click="$emit('update:modelValue', false)" variant="plain">
          </v-btn>
        </template>
        <v-card-text>
          <div class="mb-4">Pick a plan that suits your business needs. You can upgrade at any time, and downgrade after the first month. <br><strong>Choose a number of employees</strong></div>
          <v-slider
            :max="products.length - 1"
            :ticks="ticks"
            v-model="selectedPackageIndex"
            show-ticks="always"
            step="1"
            tick-size="3"
          >
          </v-slider>


          <div class="flex justify-center mt-10 mb-8">

            <div class="bg-white rounded-xl p-1">
              <v-btn @click="interval = 'month'" :color="interval === 'month' ? 'primary' : 'white'" rounded="xl" variant="flat">
                Monthly
              </v-btn>

              <v-btn @click="interval = 'year'" :color="interval === 'year' ? 'primary' : 'white'" rounded="xl" variant="flat">
                Yearly (save 20%)
              </v-btn>
            </div>

          </div>

          <div class="grid lg:grid-cols-2 mt-4">
            <div class="py-8 items-stretch hidden lg:flex">
              <div class="bg-white w-full rounded-l-xl text-center p-6">
                <h3 class="font-bold text-[22px]">Individual</h3>
                <div>Free for all Sammy users</div>


                <div class="my-4 text-[20px] font-bold">
                  <span class="text-[30px] relative"><span class="text-[16px] absolute top-1.5 right-full"> €</span>0</span>/month
                </div>

                <ul class="inline-flex flex-col text-left mx-auto gap-2 w-auto">
                  <li v-for="perk in freePerks">
                    <v-icon icon="$mdi-check-circle"></v-icon> {{perk}}
                  </li>
                </ul>

                <div class="mt-8">
                  <v-btn rounded="xl" href="https://www.sammy.nl/digitaal-visitekaartje/" target="_blank">Order a businesscard</v-btn>
                </div>
              </div>
            </div>
            <div>
              <div class="bg-[#2E2D33] w-full rounded-xl text-center p-6">
                <v-chip color="white">14-day free trial</v-chip>
                <h3 class="font-bold text-[22px]">{{currentProduct.name}}</h3>

                <div>Manage all employees in one dashboard</div>


                <div class="my-4 text-[20px] font-bold">
                  <span class="text-[30px] relative"><span class="text-[16px] absolute top-1.5 right-full"> €</span>{{currentPriceDisplay}}</span>/month
                </div>

                <ul class="inline-flex flex-col text-left mx-auto gap-2 w-auto">
                  <li v-for="perk in premiumPerks">
                    <v-icon icon="$mdi-check-circle"></v-icon> {{perk}}
                  </li>
                </ul>

                <div class="mt-8">
                  <v-btn rounded="xl"
                         color="white"
                         :loading="loading"
                         :disabled="loading"
                         @click="upgrade"
                  >Upgrade now</v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>

</template>
<script>
import moment from "moment";
import {useFirebaseAuth} from "vuefire";
import validation from "@/static/validation";
import {useFirebaseFunctions} from "@/helpers/useFirebaseFunctions";
import { httpsCallable } from "firebase/functions";
import {useNotificationStore, useUserStore, useAppStore} from "@/stores";
export default {
  setup() {
    const auth = useFirebaseAuth();
    const functions = useFirebaseFunctions();
    const notification = useNotificationStore();
    const appStore = useAppStore();
    const userStore = useUserStore();
    return {validation, auth, functions, notification, userStore, appStore}
  },
  computed: {
    products() {
      return this.appStore.products;
    },
    prices() {
      return this.appStore.prices;
    },
    currentProduct() {
      return this.products[this.selectedPackageIndex];
    },
    currentPrice() {
      if(!this.currentProduct || !this.prices.length) {
        return 0;
      }

      return this.prices
        .find(price => price.product === this.currentProduct.id && price.recurring.interval === this.interval);
    },
    currentPriceDisplay()
    {
      if(!this.currentPrice) {
        return '-';
      }
      return (this.currentPrice.unit_amount / 100 / (this.interval === 'year' ? 12 : 1)).toFixed(2);
    },
    ticks: function() {
      return Object.fromEntries(
        this.products.map((item, index) => [index, item.metadata.label || item.name])
      );
    },
  },
  data() {
    return {
      loading: false,
      interval: 'month',
      selectedPackageIndex: 1,
      freePerks: [
        'Unlimited access',
        'Use your own logo',
        'Customize with your own branding',
        'Generate QR codes',
        'VCARD integration',
        'Sammy logo visible'
      ],
      premiumPerks: [
        'Includes all features from the free plan',
        'Manage multiple business cards (admin function)',
        'Download QR codes',
        'Save business cards to Apple and Google Wallet',
        'Card analytics for all cards',
        'Lead generation for all cards',
        'Facebook and LinkedIn pixel integration',
        'Option to remove the (bottom) Sammy logo',
        'Lock card designs for brand consistency'
    ]
    }
  },
  methods: {
    upgrade() {
      const userStore = useUserStore();

      const createSession = httpsCallable(this.functions, 'createSession');
      this.loading = true
      const payload = {
        email: userStore.user.email,
        price: this.currentPrice.id,
      }
      createSession(payload)
        .then(result => {
          window.open(result.data.url,"_self")
        })
        .catch(error => {
          this.notification.setError(error.message)
        });

    }
  },
}
</script>
